import React from "react";
import classes from "./Credit.module.css";

const Credit = (props) => {
  return (
    <div className={classes.trade_card}>
      <div className={classes.card_info}>
        <div>{props.icon}</div>
        <div>For {props.description}</div>
      </div>
      <button>
        <a href={`/${props.trade}`}>get started</a>
      </button>
    </div>
  );
};

export default Credit;
