import React from "react";
import Commit from "./Commit/Commit";
import classes from "./Commitment.module.css";

const Commitment = () => {
  const goals = [
    {
      id: "1",
      title: 'Data Hub and Carbon Credit Scoring',
      info: 'Actionable data analytics which provide corporations and project developers with insights into the carbon credit market and proprietary credit scoring.'
    },
    {
      id: "2",
      title: 'Inventory Management',
      info: 'Simplify the management of the carbon credit across the ecosystem, so each platform user gets the credits when they most require it with no delays.' 
    },
    {
      id: "3",
      title: 'Carbon Credit Marketplace',
      info: 'Leverage our technology to enhance the traceability of credits and avoid double-counting while directing each user to the right match for partner and credits.'
    },
    {
      id: "4",
      title: 'Documentation Control', 
      info: 'We work with verfiers to reduce time for approvals of project, therefore reducing the lead-time for developers to access capital.'

    },
    {
      id: "5",
      title: 'Buyer Insurance Protection',
      info: 'We work with insurance companies to assume the risk for large transactions on the buyer’s side and achieve cost savings through low commission fees.'

    },
    {
      id: "6",
      title: 'NET12 Certification',
      info: 'We are creating a proprietary NET12 certification that helps determine authenticity of credits and accelerate the verification process with Verified Carbon Standard organizations.'

    },
  ];
  return (
    <div className={classes.commitment}>
      <div className={classes.bg_shadow}>
        <div className={classes.container_box}>
          <h1>We know Climate commitments are just the start.</h1>
          <p className={classes.info}>
            NET12 is focused on helping corporations reach their net zero goals
            efficiently and sustaining them long term.
          </p>
          <div className={classes.container_box__card}>
            {goals.map((goal)=>{
              return <Commit key={goal.id} data={goal}/>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commitment;
