import React from "react";
import classes from "./Header.module.css";
import Navbar from "./Navbar/Navbar";
import Netzero from "./Netzero/Netzero";
const Header = () => {
  return (
    <>
      <div className={classes.header}>
        <Navbar/>
        <div className={classes.container}>
        <Netzero/>
        </div>
      </div>
    </>
  )
};

export default Header;
