import React from "react";
import DataHubHeader from "../../components/dataHub/dataHubHeader/DataHubHeader";
import DataHubTitle from "../../components/dataHub/dataHubTitle/DataHubTitle";
import DataHubContainer from "../../components/dataHub/dataHubContainer/DataHubContainer";
import Footer from "../../components/Footer/Footer";
// import HeatMapCard from "../../components/dataHub/heatMapCard/HeatMapCard";
import PieChartCard from "../../components/dataHub/pieChartCard/PieChartCard";
import DataGraph from "../../components/dataHub/dataGraph/DataGraph";
import DataQuestion from "../../components/dataHub/dataQuestion/DataQuestion";
import classes from "./Datahub.module.css";
import DataTableCard from "../../components/dataHub/dataTableCard/DataTableCard";
import DataTopCard from "../../components/dataHub/dataTopCard/DataTopCard";
import DataTopProject from "../../components/dataHub/dataTopProject/DataTopProject";
import ReactUSAMap from "../../components/dataHub/heatMapCard/react-us-map";

const Datahub = () => {
  return (
    <div className={classes.container}>
      <DataHubHeader />
      <DataHubTitle />
      <DataHubContainer />
      <div className={classes.container_box}>
        <DataTopCard />
        <PieChartCard />
      </div>
      <div className={classes.container_box}>
        <DataGraph />
        <DataTopProject />
      </div>
      {/* <HeatMapCard /> */}
      <ReactUSAMap />
      <DataTableCard />
      <DataQuestion />
      <Footer />
    </div>
  );
};

export default Datahub;
