import React from "react";
import useFetch from "../../hooks/useFetch";
import classes from "../ExtraComponent/ExtraComponent.module.css";


export const Redirect = () => {

    const {data} = useFetch("members");
    console.log(data);
    

  return <>
  <div className={classes.container}>
    <div className={classes.container_box}>
        <h1>Thank You!</h1>
        <a href="/">Back To Home</a>
    </div>
  </div>;
  </>;
};
