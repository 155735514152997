import React from "react";
import AboutCommitment from "../components/Commitment/AboutCommitment";
import AboutHeader from "../components/Header/AboutHeader";
import Question from "../components/Question/Question";
import Footer from "../components/Footer/Footer";

const About = () => {
  return <>
    <AboutHeader />
    <AboutCommitment/>
    <Question />
    <Footer />
  </>;
};

export default About;
