import React from "react";
import classes from "./DataHubCard.module.css";

const DataHubCard = ({amount, describe}) => {
  return (
    <div className={classes.card}>
      <h1>{amount}</h1>
      <h3>{describe}</h3>
    </div>
  );
};

export default DataHubCard;
