import React from "react";
import { Redirect } from "../components/Redirect/Redirect";

const RedirectPage = () => {
    
  return (
    <>
      <Redirect/>
    </>
  );
};

export default RedirectPage;
