// import { useState } from "react";
import classes from "./DataTableCard.module.css";
import DataTable from "./dataTable/DataTable";
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

function DataTableCard() {
  // const [query, setQuery] = useState("");
  const { data, loading } = useFetch("registries");
 
  // const keys = [
  //   "projectid", 
  //   "projectname",
  //   "projectdeveloper",
  //   "projecttype",
  //   "totalcreditsissued",
  // ];

  const dataItems = data.sort(function(a,b){
    return b.vintage - a.vintage
  });

  

  // const search = (items) => {
  //   return items.slice(0,10).filter((item) =>
  //     keys.some((key) => item[key].toLowerCase().includes(query))
  //   );
  // };
  
  
  return (
    <div className={classes.app}>
      <input
        className={classes.search}
        placeholder="Search..."
        // onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />
      {loading && <CircularProgress color="inherit" />}
      {!loading && <DataTable data={dataItems} />}
    </div>
  );
}

export default DataTableCard;
