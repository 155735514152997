import React from "react";
import classes from "./PieChartCard.module.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);


const PieChartCard = () => {
  const {data, loading} = useFetch("registries/ByStateSumOfTotalCreditsIssued");

const top = data.sort(function(a,b){
    
  return b.totalcreditsissued - a.totalcreditsissued;
})
const newData = [...top.slice(0,5).map(item=>item.totalcreditsissued)];
const newLocation = [...top.slice(0,5).map(item=>item.state)];

const info = {
  
  datasets: [
    {
      label: "Total Credits Issued",
      data: newData,
      backgroundColor: ["#063970", "#279dc5", "#27C537" ,"#B8C527",  "#C54D27"],
      borderWidth: 1,
      borderColor: [ "#063970", "#279dc5", "#27C537" ,"#B8C527",  "#C54D27"]
    },
  ],
  labels: newLocation,
};
  return (
    <div className={classes.card}>
      <h2>Pie Chart </h2>
      <p>US States with the most carbon credits</p>
      {loading && <CircularProgress style={{ margin: "auto" }} color="inherit" />}
      {!loading && <Doughnut data={info} />}
    </div>
  );
};

export default PieChartCard;
