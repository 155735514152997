import React from "react";
import ExtraComponent from "../components/ExtraComponent/ExtraComponent";

const Notfound = () => {
  return (
    <>
      <ExtraComponent />
    </>
  );
};

export default Notfound;
