import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./LoginHeader.module.css";
import Logo from "../../images/favicon.png";
import { TextField } from "@mui/material";
import DataHubHeader from "../dataHub/dataHubHeader/DataHubHeader";
import { UserContext } from "../../context/UserContext";
import axios from "../../api/axios";

const LoginHeader = () => {
  // const [loginButton, setLoginButton] = useState(false);
  // const loginHandler = ()=>{
  //   setLoginButton(true);
  // }
  // const userRef = useRef();
  const { setAuth } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        "members",
        JSON.stringify({ emailAddress: email, password: pwd , returnSecureToken: true}),
        {
          headers: { "Content-Type": "application/json" }
        }
      );
      console.log(JSON.stringify(response?.data));
      setAuth({email, pwd})
      console.log(email, pwd);
      setEmail("");
      setPwd("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DataHubHeader />
      <div className={classes.container}>
        <div className={classes.container_box}>
          <div className={classes.container_box__left}>
            <div className={classes.container_box__logo}>
              <img src={Logo} alt="logo" />
            </div>
            <h1>Welcome Back!</h1>
            <p>
              NET12 is led by industry experts.
              <br />
            </p>
          </div>

          <div className={classes.container_box__right}>
            <h1>Sign in to NET12</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  type="email"
                  id="email"
                  label="Email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  required
                />
                
              </div>
              <div>
                <TextField
                  type="password"
                  id="password"
                  label="Password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div>
                <button type="submit">Sign in</button>
              </div>
              <div>
                <a href="#forgot">Forgot Password?</a>
              </div>
              <div>
                <span>
                  New to NET12? <a href="/create">Create Account.</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginHeader;
