import React from 'react';
import classes from "./DataProject.module.css";
const DataProject = ({ data }) => {

  
    
    return (
      <table className={classes.table}>
        <tbody>
          <tr>
            
            <th >Project Type</th>
            <th >Total Credits</th>
          </tr>
          {data && data.slice(0,10).map((item) => (
            <tr key={item.projecttype}>
              
              <td data-label="Project Type">{item.projecttype}</td>
              <td data-label="Project Name">{item.totalcreditsissued.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  export default DataProject;