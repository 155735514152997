import React, { Component } from "react";
import "./react-us-map.css"; /* optional for styling like the :hover pseudo-class */
import USAMap from "react-usa-map";

class ReactUSAMap extends Component {
  /* mandatory */
  mapHandler = (event) => {
    alert(event.target.dataset.name);
  };

  /* optional customization of filling per state and calling custom callbacks per state */
  statesCustomConfig = () => {
    return {
      NJ: {
        fill: "#344339",
        clickHandler: (event) =>
          console.log("Custom handler for NJ", event.target.dataset),
      },
      NY: {
        fill: "rgba(0,255,0,1)",
      },
      AL: {
        fill: "rgba(10,255,0,1)",
      },
      AZ: {
        fill: "rgba(20,255,0,1)",
      },
      AR: {
        fill: "rgba(30,255,0,1)",
      },
      AS: {
        fill: "rgba(40,255,0,1)",
      },
      CA: {
        fill: "rgba(50,255,0,1)",
      },
      CO: {
        fill: "rgba(60,255,0,1)",
      },
      CT: {
        fill: "rgba(70,255,0,1)",
      },
      DE: {
        fill: "rgba(80,255,0,1)",
      },
      DC: {
        fill: "rgba(90,255,0,1)",
      },
      FL: {
        fill: "rgba(100,255,0,1)",
      },
      NM: {
        fill: "rgba(110,255,0,1)",
      },
      NJ: {
        fill: "rgba(120,255,0,1)",
      },
      NH: {
        fill: "rgba(130,255,0,1)",
      },
      NV: {
        fill: "rgba(140,255,0,1)",
      },
      NE: {
        fill: "rgba(150,255,0,1)",
      },
      MT: {
        fill: "rgba(160,255,0,1)",
      },
      MO: {
        fill: "rgba(170,255,0,1)",
      },
      MS: {
        fill: "rgba(180,255,0,1)",
      },
      MN: {
        fill: "rgba(190,255,0,1)",
      },
      MI: {
        fill: "rgba(200,255,0,1)",
      },
      NC: {
        fill: "rgba(0,255,10,1)",
      },
      ND: {
        fill: "rgba(10,255,20,1)",
      },
      MP: {
        fill: "rgba(20,255,30,1)",
      },
      OH: {
        fill: "rgba(30,255,40,1)",
      },
      OK: {
        fill: "rgba(40,255,50,1)",
      },
      OR: {
        fill: "rgba(50,255,60,1)",
      },
      PA: {
        fill: "rgba(60,255,70,1)",
      },
      PR: {
        fill: "rgba(70,255,80,1)",
      },
      RI: {
        fill: "rgba(80,255,90,1)",
      },
      SC: {
        fill: "rgba(90,255,100,1)",
      },
      SD: {
        fill: "rgba(100,255,110,1)",
      },
      TN: {
        fill: "rgba(110,255,120,1)",
      },
      TX: {
        fill: "rgba(120,255,130,1)",
      },
      TT: {
        fill: "rgba(130,255,140,1)",
      },
      UT: {
        fill: "rgba(140,255,150,1)",
      },
      VT: {
        fill: "rgba(160,255,150,1)",
      },
      VA: {
        fill: "rgba(170,255,150,1)",
      },
      VI: {
        fill: "rgba(180,255,0,1)",
      },
      WA: {
        fill: "rgba(180,255,150,1)",
      },
      WV: {
        fill: "rgba(190,255,150,1)",
      },
      WI: {
        fill: "rgba(200,255,150,1)",
      },
      WY: {
        fill: "rgba(200,255,160,1)",
      },
      MD: {
        fill: "rgba(0,245,0,1)",
      },
      ME: {
        fill: "rgba(0,235,0,1)",
      },
      LA: {
        fill: "rgba(0,225,0,1)",
      },
      KY: {
        fill: "rgba(0,215,0,1)",
      },
      KS: {
        fill: "rgba(0,205,0,1)",
      },
      IA: {
        fill: "rgba(0,195,0,1)",
      },
      IN: {
        fill: "rgba(0,185,0,1)",
      },
      IL: {
        fill: "rgba(0,175,0,1)",
      },
      ID: {
        fill: "rgba(0,165,0,1)",
      },
      HI: {
        fill: "rgba(0,155,0,1)",
        
      },
    };
  };


  render() {
    return (
      <div className="card">
        <h2>Heat Map </h2>
      <p>Concentration of carbon credits across the United States</p>
        <div className="react_map">
        <USAMap
          customize={this.statesCustomConfig()}
          onClick={this.mapHandler}
          defaultFill="rgba(0,100,0,1)"
        />
        
      </div>
       </div>
    );
  }
}

export default ReactUSAMap;
