import React from "react";
import Create from "../components/Create/Create";

const Account = () => {
  return <>
    <Create/>
  </>;
};

export default Account;
