import React from "react";
import classes from "./Commit.module.css";

const Commit = ({data}) => {
  return (
    <div className={classes.card}>
      <h2>{data.title}</h2>
      <p>
        {data.info}
      </p>
    </div>
  );
};

export default Commit;
