import React from "react";
import AboutCommit from "./Commit/AboutCommit";
import classes from "./AboutCommitment.module.css";
import image1 from "../../images/Clyde.png";
import image2 from "../../images/Ibhade.png";
import image3 from "../../images/Obiahu.png";

const Commitment = () => {
  const profiles = [
    {
      id: "1",
      image: image1,
      name: 'Clyde-Blaise Niba',
      role:'CEO, Co-Founder',
      emailLink: 'cniba@mit.edu',
      info: 'Background within oil & energy supply chain management and operations. Has global leadership experience within the UK, UAE and Europe. Finance experience through working with Goldman Sachs as an Investment banking Summer Associate.'
    },
    {
      id: "2",
      image: image2,
      name: 'Ibhade Eigbobo',
      role:'COO, Co-Founder',
      emailLink: 'ieigbobo@mba2023.hbs.edu',
      info: '7 years experience in downstream oil and gas process engineering design, capital and operations project and program management. Also worked as a Venture Capital associate in the Enterprise SAAS, future of work, climate, industrial and transportation tech space. Early stage Development and Strategy experience in EV charging infrastructure and remittance via blockchain.'
    },
    {
      id: "3",
      image: image3,
      name: 'Victor Obiahu',
      role:'CTO, Co-Founder',
      emailLink: 'vobiahu@mit.edu',
      info: '7 years experience of software engineering, data analytics and engineering management experience within financial services and retail tech. Product management experience  through working with NVIDIA over Summer 2022 developing virtual worlds through digital twin technology.'
    },
    
  ];
  return (
    <div className={classes.commitment}>
      <div className={classes.bg_shadow}>
        <div className={classes.container_box}>
          <div className={classes.container_box__card}>
            {profiles.map((profile)=>{
              return <AboutCommit key={profile.id} data={profile}/>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commitment;
