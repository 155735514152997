import React from "react";
import classes from "./Insight.module.css";
import DataHubHeader from "../dataHub/dataHubHeader/DataHubHeader";
import Footer from "../Footer/Footer";
// import InsightImage from "../../images/insight.jpg";
import Image1 from "../../images/image1.png";
import Image2 from "../../images/image2.png";
import Image3 from "../../images/image3.png";
import Image4 from "../../images/image4.png";
import Image5 from "../../images/image5.png";
import Image6 from "../../images/image6.png";
import Image7 from "../../images/Image7.jpg";
import Image8 from "../../images/Image8.jpg";

const Insight = () => {
  return (
    <>
      <DataHubHeader />
      <div className={classes.sub_header}>
        <div className={classes.sub_header__box}>
        <h1>Insights</h1>
        <h3> A collection of articles on voluntary carbon credits</h3>
        </div>
      </div>
      
      <div className={classes.container_insight}>
        <div className={classes.image_box}>
          <img src={Image3} alt="Insight image" />
          <h2>
            The leading marketplace for voluntary carbon credits
          </h2>
          
        </div>
        <hr />
        <div className={classes.image_articles}>
          <h2>Collected Articles</h2>
          <div>

          <a href="https://carboncredits.com/the-ultimate-guide-to-understanding-carbon-credits/" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image1} alt="Article One" />
            </div>
            <div className={classes.image_desc}>

            <p>
            Carbon Credits 101 : A beginner’s intro to navigating voluntary
            carbon credits
            </p>
            <h5>3 January 2023</h5>
            </div>
            
          </a>
          </div>
          <a href="https://conferenceindex.org/conferences/climate-change/united-states" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image2} alt="Article Two" />
            </div>
            
          <div className={classes.image_desc} >

            <p>
            Global climate change conferences from 2022 - 2024
            </p>
            <h5>18 December 2022</h5>
          </div>
            
          </a>
          <a href="https://www.crediblecarbon.com/how-it-works/the-process/" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image6} alt="Article Three" />
            </div>
            <div className={classes.image_desc} >

            <p>
            Carbon Capture Utilization and major market players
            </p>
            <h5>16 December 2022</h5>
            </div>
          </a>
          <a href="https://www.goldstandard.org/blog-item/carbon-pricing-what-carbon-credit-worth" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image4} alt="Article Four" />
            </div>
            <div className={classes.image_desc} >

            <p>
            Global carbon pricing and its determinants
            </p>
            <h5>10 November 2022</h5>
            </div>
          </a>
          <a href="https://www.mckinsey.com/capabilities/sustainability/our-insights/a-blueprint-for-scaling-voluntary-carbon-markets-to-meet-the-climate-challenge" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image5} alt="Article Five" />
            </div>
            <div className={classes.image_desc} >

            <p>
            Market experts share their views about Voluntary carbon credits and the climate challenge
            </p>
            
            <h5>30 October 2022</h5>
            </div>
          </a>
          <a href="https://www.cambridgeassociates.com/insight/2023-outlook-sustainability-impact/" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image8} alt="Article Six" />
            </div>
            <div className={classes.image_desc} >

            <p>
            2023 Outlook: Sustainability & Impact
            </p>
            
            <h5>15 October 2022</h5>
            </div>
          </a>
          <a href="https://www.ctvc.co/steelin-the-show/" className={classes.image_article__box}>
            <div className={classes.image}>
              <img src={Image7} alt="Article Seven" />
            </div>
            <div className={classes.image_desc} ></div>
            <p>
            Forging a new path for steel decarbonization
            </p>
            
            <h5>23 September 2022</h5>
          </a>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Insight;
