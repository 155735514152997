import React from "react";
import Insight from "../../components/insight/Insight";

const InsightPage = () => {
  return <>
  <Insight/>
  </>;
};

export default InsightPage;
