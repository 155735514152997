import React from "react";
import classes from "./DataGraph.module.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

ChartJs.register(LineElement, CategoryScale, LinearScale, PointElement);

const DataGraph = () => {

  const {data, loading} = useFetch("registries/ByYearSumOfTotalCreditsIssued");

  const top = data.sort(function(a,b){
    return a.year - b.year
  });

  const newvar = top.slice(-7);

  
  const newData = [...newvar.map(item=>item.totalcreditsissued)];
  let listData = [];
  newData.map((singleData)=>{

    if (singleData >= 1000000){
      let singleItem = Math.round(singleData / 1000000);
      listData.push(singleItem);
    }
    return null;
  })
 
  
  const year = [...newvar.map(item=>item.year)];
  const datas = {
    labels: year,
    datasets: [
      {
        data: listData,
        backgroundColor: "transparent",
        borderColor: "#4B654F",
        pointBorderColor: "#4B654F",
        pointBorderWidth: 4,
        tension: 0.5
      },
    ],
  };
  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 2,
          callback: (value) => value + 'M' ,
        },
        grid: {
          borderDash: [10],
        },
      },
    },
  };
  return (
    <div className={classes.card}>
      <h2>Line Graph </h2>
      <p>Quantity of carbon credits in the US over time</p>
      <div style={{ height: '450px', marginTop: "1rem", width: '100%' }}>
      {loading && <CircularProgress style={{ margin: "auto" }} color="inherit" />}
        {!loading && <Line options={options} data={datas} />}
      </div>
    </div>
  );
};

export default DataGraph;
