import React from "react";
import ExchangeNav from "../components/ExchangeBuyer/ExchangeBuyer";

const Buyer = () => {
  return (
    <>
      <ExchangeNav />
    </>
  );
};

export default Buyer;
