import React, { useState, useContext } from "react";
import Logo from "../../../images/favicon.png";
import classes from "./DataHubHeader.module.css";
import { AiOutlineMenu } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import { AiOutlineArrowRight } from "react-icons/ai";
import { UserContext } from "../../../context/UserContext";
import Navbar from "../../Header/Navbar/Navbar";


const DataHubHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenu = () => {
    setIsSubmenuOpen(true);
  };
  const closeSubmenu = () => {
    setIsSubmenuOpen(false);
  };
  return (
    <header className={classes.navbar}>
      <a href="/">
        <img src={Logo} alt="logo" />
      </a>
      <nav className={`${classes.menu_bar} ${isOpen && classes.menu_toggle}`}>
        <div className={classes.header_nav}>
          <a href="/">Home</a>
          <div className={classes.drop}>
            <button
              className={classes.drop_btn}
              onMouseEnter={submenu}
              onMouseLeave={closeSubmenu}
            >
              Onboarding
            </button>
            {/* <BiDownArrowAlt/> */}
            <ul
              className={`${isSubmenuOpen ? classes.dropdown : "hidden"}`}
              onMouseEnter={submenu}
              onMouseLeave={closeSubmenu}
            >
              <li>
                <a href="/buyer">Corporations</a>
              </li>
              <li>
                <a href="/seller">Project Developers</a>
              </li>
            </ul>
          </div>
          <a href="/about">About Us</a>
          <a href="/datahub">Data Hub</a>
          <a href="/insights">Insights</a>
        </div>
        <div
          className={`${
            !isLoggedIn ? classes.not_logged__in : classes.logged_in
          }`}
        >
          <span>|</span>
          <a href="/login">Sign in</a>
          <a href="/create">Sign up</a>
        </div>
        <div
          className={`${
            isLoggedIn ? classes.show_logged__in : classes.hide_logged__in
          }`}
        >
          <a href="/login">Logout</a>
          <AiOutlineArrowRight />
        </div>
      </nav>
      <div className={classes.nav_toggle} onClick={() => setIsOpen(!isOpen)}>
        {isOpen && <TfiClose />}
        {!isOpen && <AiOutlineMenu />}
      </div>
    </header>
  );
};

export default DataHubHeader;
