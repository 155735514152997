import React from "react";
import classes from "./AboutCommit.module.css";

const AboutCommit = ({data}) => {
  return (
    <div className={classes.card}>
      <div className={classes.image}>
        <img src={data.image} alt="profile"/>
      </div>
      <div>
        <h1>{data.name}</h1>
        <h2>{data.role}</h2>
        <h3>{data.emailLink}</h3>
      </div>
      <div>
        <p>{data.info}</p>
      </div>
    </div>
  );
};

export default AboutCommit;

