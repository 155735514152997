import React from "react";
import classes from "../ExchangeBuyer/ExchangeBuyer.module.css";
import Navbar from "../Header/Navbar/Navbar";
import { Avatar } from "@mui/material";
const Header = () => {
  return (
    <>
      <div className={classes.header}>
        <Navbar />
      </div>
      <div className={classes.container}>
        <div className={classes.container_box}>
          <div className={classes.container_box__top}>
            <div>
              <Avatar />
              <h2>Project Developers</h2>
            </div>
            <div className={classes.box_remark}>
              <p>The leading marketplace for voluntary carbon credits</p>
            </div>
          </div>
          <div className={classes.container_box__bottom}>
            <form>
              <div>
                <label>
                  Company Name <span>*</span>
                </label>
                <input type="text" />
              </div>
              <div>
                <label>Company Size</label>
                <input type="number" />
              </div>
              <div>
                <label>
                  Primary Contact <span>*</span>
                </label>
                <input type="text" />
              </div>
              <div>
                <label>State</label>
                <input type="text" />
              </div>
              <div>
                <label>
                  Credits Available <span>*</span>
                </label>
                <input type="number" />
              </div>
              <div>
                <label>
                  Available Offsets <span>*</span>
                </label>
                <input type="number" />
              </div>
              <div>
                <label>Price per Credit</label>
                <input type="number" />
              </div>
              <div>
                <label>
                  Carbon Credit ID <span>*</span>
                </label>
                <input type="number" />
              </div>
              <div>
                <label>Upload Proposal</label>
                <div style={{ position: "relative", width: "50%" }}>
                  <input type="file" style={{ width: "100%", border: "none" }} id="upload" />
                  <label
                    style={{
                      position: "absolute",
                      backgroundColor: "#577564",
                      color: "#fff",
                      top: 0,
                      left: 0,
                      height: "30px",
                      padding: "4px 20px",
                      width: "92px",
                      cursor: "pointer",
                      borderRadius: "3px",
                    }}
                    htmlFor="upload"
                  >
                    Upload
                  </label>
                </div>
              </div>
              <div>
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
