import React from "react";
import classes from "./Footer.module.css";
import Logo from "../../images/favicon.png";

const Footer = () => {
  return <div className={classes.footer}>
    <ul>
      <li>Terms of Use</li>
      <li>FAQ</li>
      <li>
        <img src={Logo} alt="logo"/>
      </li>
      <li>Privacy Policy</li>
      <li><a href="mailto:helloteam@net-12.com">Contact</a></li>
    </ul>
  </div>;
};

export default Footer;
