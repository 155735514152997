import React from "react";
import Header from "../components/Header/Header";
import Trade from "../components/Trade/Trade";
import Commitment from "../components/Commitment/Commitment";
import Question from "../components/Question/Question";
import Footer from "../components/Footer/Footer";
// import useFetch from "../hooks/useFetch";

const Exchange = () => {
// const {data} = useFetch("members")
// console.log(data);


  return (
    <>
      <Header />
      <Trade />
      <Commitment />
      <Question />
      <Footer />
    </>
  );
};

export default Exchange;
