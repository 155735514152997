import React, { useContext, useState, useEffect } from "react";
import classes from "./Create.module.css";
import Logo from "../../images/favicon.png";
import { TextField } from "@mui/material";
import axios from "../../api/axios";
import DataHubHeader from "../dataHub/dataHubHeader/DataHubHeader";
import { UserContext } from "../../context/UserContext";

const Create = () => {
  const authCtx = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [serverError, setServerError] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [organisation, setOrganisation] = useState("");

  let message = "Password does not match";

// the generated token will be useful
  const submitHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      if (password === confirmPassword) {
        const response = await axios.post(
          "members",

          JSON.stringify({
            firstName: fname,
            lastName: lname,
            username: username,
            emailAddress: email,
            password: password,
            organization: organisation,
            returnSecureToken: true,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log(JSON.stringify(response?.data));
        setIsError(false);
        setFname("");
        setLname("");
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setOrganisation("");
        setServerError("");
        // authCtx.login(idToken);
      } else {
        setIsError(true);
      }
    } catch (error) {
      console.log(error);
      setServerError("Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <>
      <DataHubHeader />
      <div className={classes.container}>
        <div className={classes.container_box}>
          <div className={classes.container_box__left}>
            <div className={classes.container_box__logo}>
              <img src={Logo} alt="logo" />
            </div>
            <h1>Welcome Back!</h1>
            <p>
              NET12 is led by industry experts.
              <br />
            </p>
          </div>

          <div className={classes.container_box__right}>
            <h1>Create Your NET12 Account</h1>
            <h1 style={{ color: "red", marginBottom: "1rem", marginTop: "0" }}>
              {serverError}
            </h1>
            <form onSubmit={submitHandler}>
              <div>
                <TextField
                  id="firstname"
                  label="First Name"
                  variant="outlined"
                  style={{ marginRight: ".5rem" }}
                  onChange={(e) => setFname(e.target.value)}
                  value={fname}
                  autoFocus
                  autoComplete="off"
                  required
                />
                <TextField
                  id="lastname"
                  label="Last Name"
                  variant="outlined"
                  onChange={(e) => setLname(e.target.value)}
                  value={lname}
                  autoComplete="off"
                  required
                />
              </div>
              <div>
                <TextField
                  type="text"
                  id="userame"
                  label="Username"
                  variant="outlined"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  fullWidth
                  autoComplete="off"
                  required
                />
              </div>
              <div>
                <TextField
                  type="email"
                  id="email"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  fullWidth
                  autoComplete="off"
                  required
                />
              </div>
              <div>
                <TextField
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  style={{ marginRight: ".5rem" }}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  autoComplete="off"
                  required
                />
                <TextField
                  type="password"
                  id="confirmpassword"
                  label="Confirm"
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  required
                />
              </div>
              <p style={{ color: "red", marginBottom: "1rem", marginTop: "0" }}>
                {isError ? message : ""}
              </p>
              <div>
                <TextField
                  type="text"
                  id="organisation"
                  label="Organisation/Institution"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setOrganisation(e.target.value)}
                  value={organisation}
                  autoComplete="off"
                  required
                />
              </div>
              <div>
                {!isLoading && (
                  <button type="submit">
                    {/* <a
                      href="/redirect"
                      style={{ color: "#fff", textDecoration: "none" }}
                    > */}
                      Sign up
                    {/* </a> */}  
                    {/* redirect user to home page */}
                  </button>
                )}
                {isLoading && (
                  <button
                    style={{ backgroundColor: "white", color: "#ccc" }}
                    disabled
                  >
                    Sending
                  </button>
                )}
              </div>
              <div>
                <a href="#forgot">Forgot Password?</a>
              </div>

              <div>
                <span>
                  Already has an account? <a href="/login">Login</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
