import React from "react";
import classes from "./Trade.module.css";
import Credit from "./Credit/Credit";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineStorefront } from "react-icons/md";

const Trade = () => {
  return (
    <div className={classes.trade}>
      <div className={classes.bg_shadow}>
        <div className={classes.credit_container}>
          <p>Partner with us to trade your carbon credits.</p>
          <div className={classes.trade_box}>
              <Credit icon={<AiOutlineShoppingCart />} description={"Corporations"} trade={"buyer"}/>
              <Credit icon={<MdOutlineStorefront />} description={"Project Developers"} trade={"seller"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
