import React from "react";
import classes from "./Question.module.css";
const Question = () => {
  return <div className={classes.question}>
    <div className={classes.bg_shadow}>
      <h1>Have a question?</h1>
      <a href="mailto:helloteam@net-12.com">Contact Us</a>
    </div>
  </div>;
};

export default Question;

