import React from "react";
import classes from "./DataHubContainer.module.css";
import DataHubCard from "./dataHubCard/DataHubCard";
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";
const DataHubContainer = () => {
  const {loading, data} = useFetch('registries/ByStateSumOfTotalCreditsIssued');

  const stateData = data.map((singleItem)=>{
    return singleItem.totalcreditsissued
  })


  const sum = stateData.reduce((prevValue, curValue)=>{
    return prevValue + curValue
  },0)
  const totalSum = Math.round((sum / 1000000))
  const items = [
    {
      id: "1",
      amount: `${totalSum}M`,
      describe: "carbon credits currently available in the US",
    },
    {
      id: "2",
      amount: "$37",
      describe: "average price per carbon credit (current)",
    },
    {
      id: "3",
      amount: "795M",
      describe: "carbon credits currently available globally",
    },
  ];
  // console.log(items);

  return (
    <div className={classes.container}>
      {loading ? <CircularProgress color="inherit" /> : items.map((item) => {
        return (
          <DataHubCard
            key={item.id}
            amount={item.amount}
            describe={item.describe}
          />
        );
      })}
    </div>
  );
};

export default DataHubContainer;
