import React from "react";
import classes from "./DataTopProject.module.css";
import DataProject from "./dataProject/DataProject";
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

function DataTopProject() {

  const { data, loading } = useFetch("registries/ProjectTypesByTotalCreditsIssued");
  const top = data.sort(function(a,b){
    
    return b.totalcreditsissued - a.totalcreditsissued
  })

  return (
    <div className={classes.app}>
      <h2>Preferred Offsets with most carbon credits</h2>
      {loading && <CircularProgress color="inherit" />}
      {!loading && <DataProject data={(top)} />}
    </div>
  );
}

export default DataTopProject;
