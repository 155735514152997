import React from 'react';
import classes from "./DataTable.module.css";
const DataTable = ({ data }) => {

    return (
      <table className={classes.table}>
        <thead>
          <tr>
            <th> Project ID</th>
            <th>Project Vintage</th>
            <th>Project Name</th>
            <th>Project Developer</th>
            <th>Project Type</th>
            <th>Methodology Protocal</th>
            <th>Protocal Version</th>
            <th>Total Credits</th>
            <th>Site Location</th>
            <th>State</th>
            <th>Country</th>
            <th>Project Company</th>
          </tr>
        </thead>
        <tbody>
          {data && data.map((item) => (
            <tr key={item.id} >
              <td data-label="Project ID">{item.projectid}</td>
              <td data-label="Project Vintage">{item.vintage}</td>
              <td data-label="Project Name">{item.projectname}</td>
              <td data-label="Project Developer">{item.projectdeveloper}</td>
              <td data-label="Project Type">{item.projecttype}</td>
              <td data-label="Project Protocal">{item.projectmethodologyprotocol}</td>
              <td data-label="Project Version">{item.methodologyprotocolversion}</td>
              <td data-label="Project rCedits">{item.totalcreditsissued}</td>
              <td data-label="Project Site">{item.projectsitelocation}</td>
              <td data-label="Project State">{item.projectsitestate}</td>
              <td data-label="Project Country">{item.projectsitecountry}</td>
              <td data-label="Project Vvb">{item.projectvvb}</td>
            </tr>
          ))}
          </tbody>
          
    </table>
    );
  };
  
  export default DataTable;