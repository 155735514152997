import React from "react";
import classes from "./DataTopCard.module.css";
import DataTop from "./dataTop/DataTop";
import useFetch from "../../../hooks/useFetch";
import { CircularProgress } from "@mui/material";

function DataTableCard() {

  const { data, loading } = useFetch("registries/ByStateSumOfTotalCreditsIssued");
  const top = data.sort(function(a,b){
    
    return b.totalcreditsissued - a.totalcreditsissued
  })

  return (
    <div className={classes.app}>
      <h2>US States with the most carbon credits</h2>
      {loading && <CircularProgress color="inherit" />}
      {!loading && <DataTop data={(top)} />}
    </div>
  );
}

export default DataTableCard;
