
import React from "react";
import classes from "./ExtraComponent.module.css";

const ExtraComponent = () => {
  return <div className={classes.container}>
    <div className={classes.container_box}>
        <h1>Oops Page Not Found!!!</h1>
        <a href="/">Back To Home</a>
    </div>
  </div>;
};

export default ExtraComponent;
