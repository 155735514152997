import React, { useState } from "react";
import classes from "./ExchangeBuyer.module.css";
import Navbar from "../Header/Navbar/Navbar";
import { Avatar } from "@mui/material";
import axios from "../../api/axios";


const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState();
  const [contact, setContact] = useState("");
  const [state, setState] = useState("");
  const [credits, setCredits] = useState();
  const [offset, setOffset] = useState([]);
  const [budget, setBudget] = useState();


  const submitHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {

        const response = await axios.post(
          "buyers",

          JSON.stringify({
            companyName: companyName,
            companySize: companySize,
            primaryContact: contact,
            state: state,
            numCreditsDesired: credits,
            preferredOffsets: offset.split(",").sort(),
            budget: budget,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log(JSON.stringify(response?.data));
        setCompanyName("");
        setCompanySize("");
        setContact("");
        setState("");
        setCredits("");
        setOffset("");
        setBudget("");
        setServerError("");
    } catch (error) {
      console.log(error);
      setServerError("Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={classes.header}>
        <Navbar />
      </div>
      <div className={classes.container}>
      
        <div className={classes.container_box}>
        <h1 style={{ color: "red", marginBottom: "1rem", marginTop: "0" ,margin: "auto"}}>
              {serverError}
            </h1>
          <div className={classes.container_box__top}>
            <div>
              <Avatar />
              <h2>Corporations</h2>
            </div>
            <div className={classes.box_remark}>
              <p>The leading software tool for your sustainability needs</p>
            </div>
          </div>
          <div className={classes.container_box__bottom}>
            <form onSubmit={submitHandler}>
              <div>
                <label>Company Name <span>*</span></label>
                <input type="text" value={companyName} onChange={(e)=>setCompanyName(e.target.value)} required/>
              </div>
              <div>
                <label>Company Size</label>
                <input type="number" value={companySize} onChange={(e)=>setCompanySize(e.target.value)}/>
              </div>
              <div>
                <label>Primary Contact <span>*</span></label>
                <input type="text" value={contact} onChange={(e)=>setContact(e.target.value)} required/>
              </div>
              <div>
                <label>State</label>
                <input type="text" value={state} onChange={(e)=>setState(e.target.value)} />
              </div>
              <div>
                <label>Credits Desired <span>*</span></label>
                <input type="number" value={credits} onChange={(e)=>setCredits(e.target.value)} required />
              </div>
              <div>
                <label>Preferred Offsets <span>*</span></label>
                <input type="text" value={offset} onChange={(e)=>setOffset(e.target.value)}/>
              </div>
              <div>
                <label>Budget</label>
                <input type="number" value={budget} onChange={(e)=>setBudget(e.target.value)} />
              </div>
              <div>
              {isLoading && (
                  <button
                    style={{ backgroundColor: "white", color: "#ccc" }}
                    disabled
                  >
                    Sending
                  </button>
                )}
                {!isLoading && <button type="submit">Submit</button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
