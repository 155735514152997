import React from "react";
import classes from "./Netzero.module.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Button from '@mui/material/Button';

const Netzero = () => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const emailHandler = ()=>{
    console.log(email);
    
  }
  const FormHandler = ()=>{
    // setOpen(open);
    return(
       <form className={classes.form}>
          <div><h2>Access our data hub by providing your email</h2></div>
          <div>
          <label>Email</label>
          <input type="email" name="email" autoFocus value={email}
          onChange={(e)=>setEmail(e.target.value)}
          />
          </div>
          <button type="submit" onClick={emailHandler} >Submit</button>
        </form>
    )
  }


  return (
  <>
  <div className={classes.short_note}>
    <h1>NET ZERO</h1>
    <h2>Fast. Secure. Efficient.</h2>
    <button onClick={handleToggle}>Access Our Data Hub</button>
  </div>
  <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
        style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', padding: 0 }}
      >
        {/* <CircularProgress color="inherit" /> */}
       <FormHandler/>
      </Backdrop>
  </>
  )
};

export default Netzero;