import React from 'react';
import Exchange from './pages/Exchange';
import About from './pages/About';
import Datahub from './pages/dataHub/Datahub';
import Login from './pages/Login';
import Notfound from './pages/Notfound';
import Account from './pages/Account';
import Buyer from './pages/Buyer';
import Seller from './pages/Seller';
import InsightPage from './pages/insightPage/InsightPage';
import RedirectPage from './pages/RedirectPage';
import { Route, Routes } from "react-router-dom"

function App() {
 
  return (
   <Routes>
    <Route exact path='/' element={<Exchange/>} />
    <Route exact path='/about' element={<About/>} />
    <Route exact path='/datahub' element={<Datahub/>} />
    <Route exact path='/insights' element={<InsightPage/>} />
    <Route exact path='/login' element={<Login/>} />
    <Route exact path='/create' element={<Account/>} />
    <Route exact path='/buyer' element={<Buyer/>} />
    <Route exact path='/seller' element={<Seller/>} />
    <Route exact path='/redirect' element={<RedirectPage/>} />
    <Route path='*' element={<Notfound/>} />
   </Routes>
  );
}

export default App;
