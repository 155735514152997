import React from "react";
import ExchangeSeller from "../components/ExchangeSeller/ExchangeSeller"

const Seller = () => {
  return <>
    <ExchangeSeller/>
  </>;
};

export default Seller;
