import React from "react";
import classes from "./Netzero.module.css";

const Meeting = () => {
  return <div className={classes.short_note}>
    <h1>Meet the team.</h1>
    <h2>NET12 is led by industry experts.</h2>
  </div>;
};

export default Meeting;
