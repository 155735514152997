import React from "react";
import classes from "./Header.module.css";
import Navbar from "./Navbar/Navbar";
import Meeting from "./Netzero/Meeting";
const AboutHeader = () => {
  return (
    <>
      <div className={classes.header}>
        <Navbar/>
        <div className={classes.container}>
        <Meeting/>
        </div>
      </div>
    </>
  )
};

export default AboutHeader;
